import { Header } from '../../Header/';
import style from './Work.module.scss';
import work from './portfolio_assets/emailSignature_placeholder_thumbnail.jpg';
import fleetViewThumbnail from './portfolio_assets/fleetView_placeholder_thumbnail.jpg';
import work1 from './portfolio_assets/bulkSignature.jpg';
import work2 from './portfolio_assets/mediametrics.jpg';
import work3 from './portfolio_assets/example2.jpg';
import work4 from './portfolio_assets/example3.jpg';
import work5 from './portfolio_assets//fleetView.jpg';
import work6 from './portfolio_assets/ToyotaOEM_MLP.jpg';
import toyotaOem from './portfolio_assets/toyotaOEM_thumbnail.jpg';
import work7 from './portfolio_assets/tenTrucks.png';
import work8 from './portfolio_assets/kerkman.jpg';
import work9 from './portfolio_assets/dealersocket_dashboard.jpg';
import work10 from './portfolio_assets/tep.jpg';
import { useCase, gfp } from './Contact';

import { ReactComponent as OpenCaseWhiteBtn} from './assets/open_case_study_button.svg';
import { ReactComponent as OpenCaseBlueBtn} from './assets/OpenCaseBlueBtn.svg';

export const Work = ({
  ...props
}) => {
  return(
    <>
      <Header
        line1={`My name is Vasiliy, I'm a`}
        line2={`Product and UI/UX Designer`}
      />
      <div className={style.content}>
      
        {/* FleetView Usecase */}
        <div className={style.useCase}>

          <div className={style.col}>
            <a className={[style.OpenCaseButton, style.bottom].join(' ')} href='https://www.figma.com/proto/1wkq1MuCHG11boDh849LQa/Portfolio?page-id=3689%3A546&node-id=3723-1506&node-type=canvas&viewport=914%2C372%2C0.05&t=akUXl5hETQK3Dyou-1&scaling=scale-down-width&content-scaling=fixed' target="_blank" >
              <OpenCaseBlueBtn className={style.bottom} />
            </a>
            <a href='https://www.figma.com/proto/1wkq1MuCHG11boDh849LQa/Portfolio?page-id=3689%3A546&node-id=3723-1506&node-type=canvas&viewport=914%2C372%2C0.05&t=akUXl5hETQK3Dyou-1&scaling=scale-down-width&content-scaling=fixed' target="_blank">
              <img src={fleetViewThumbnail} />
            </a>
          </div>

          <div className={style.col}>
            <div className={style.line}>
              <h3>
                <a href='https://www.figma.com/proto/1wkq1MuCHG11boDh849LQa/Portfolio?page-id=3689%3A546&node-id=3723-1506&node-type=canvas&viewport=914%2C372%2C0.05&t=akUXl5hETQK3Dyou-1&scaling=scale-down-width&content-scaling=fixed' target='_blank'>
                  Global Fleet Platform
                </a>
              </h3>
              <p>
                Get started proactively managing your fleet with the technology designed to safeguard your drivers.
              </p>
            </div>

            <div className={style.grid}>
              <div className={style.item}>
                <p className={style.label}>Client</p>
                <p>Solera | Omnitracs</p>
              </div>
              <div className={style.item}>
                <p className={style.label}>Role</p>
                <p>UI/UX Designer III</p>
              </div>
              <div className={style.item}>
                <p className={style.label}>Location</p>
                <p>Westlake, TX, US</p>
              </div>
            </div>

            <div className={style.grid}>
              <div className={style.item}>
                <p className={style.label}>Design System</p>
                <p>Brand Solera</p>
              </div>
              <div className={style.item}>
                <p className={style.label}>App Type</p>
                <p>SaaS, B2B</p>
              </div>
              <div className={style.item}>
                <p className={style.label}>Duration</p>
                <p>3 years</p>
              </div>
            </div>
            
            <div className={style.line}>
              <h2>Tools & Skills</h2>
              <div className={style.badges}>
                {gfp.map((item => (
                  <div>
                    <div
                      className={style.badge}
                      style={{ gap: `${item.icon ? item.gap : ''}` }}
                    >
                      <div>{item.icon}</div>
                      <span>{item.name}</span>
                    </div>
                  </div>
                )))}
              </div>
            </div>

          </div>

        </div>

        {/* Bulk Signature */}

        <div className={style.useCase}>
          <div className={style.col}>
            <div className={style.line}>
              <h3>
              <a href='https://www.figma.com/proto/1wkq1MuCHG11boDh849LQa/Portfolio?page-id=3519%3A2&node-id=3559-4745&node-type=canvas&viewport=914%2C507%2C0.04&t=6DNIOA3QVZVTHXSj-1&scaling=scale-down-width&content-scaling=fixed' target="_blank">
                  Email Signature Management application for Google Workspace
                </a>
              </h3>
              <p>
                Easily create and manage beautiful email signatures for your entire domain with just a few clicks, no manual updates needed.
              </p>
            </div>

            <div className={style.grid}>
              <div className={style.item}>
                <p className={style.label}>Client</p>
                <p>Bulk Signature</p>
              </div>
              <div className={style.item}>
                <p className={style.label}>Role</p>
                <p>Design Lead</p>
              </div>
              <div className={style.item}>
                <p className={style.label}>Location</p>
                <p>Dallas, Texas, US</p>
              </div>
            </div>

            <div className={style.grid}>
              <div className={style.item}>
                <p className={style.label}>Design System</p>
                <p>Mantis</p>
              </div>
              <div className={style.item}>
                <p className={style.label}>App Type</p>
                <p>SaaS</p>
              </div>
              <div className={style.item}>
                <p className={style.label}>Duration</p>
                <p>1 year</p>
              </div>
            </div>
            
            <div className={style.line}>
              <h2>Tools & Skills</h2>
              <div className={style.badges}>
                {useCase.map((item => (
                  <div>
                    <div
                      className={style.badge}
                      style={{ gap: `${item.icon ? item.gap : ''}` }}
                    >
                      <div>{item.icon}</div>
                      <span>{item.name}</span>
                    </div>
                  </div>
                )))}
              </div>
            </div>

          </div>
          
          <div className={style.col}>
            <a className={[style.OpenCaseButton, style.top].join(' ')} href='https://www.figma.com/proto/1wkq1MuCHG11boDh849LQa/Portfolio?page-id=3519%3A2&node-id=3559-4745&node-type=canvas&viewport=914%2C507%2C0.04&t=6DNIOA3QVZVTHXSj-1&scaling=scale-down-width&content-scaling=fixed' target="_blank" >
              <OpenCaseWhiteBtn />
            </a>
            <a href='https://www.figma.com/proto/1wkq1MuCHG11boDh849LQa/Portfolio?page-id=3519%3A2&node-id=3559-4745&node-type=canvas&viewport=914%2C507%2C0.04&t=6DNIOA3QVZVTHXSj-1&scaling=scale-down-width&content-scaling=fixed' target="_blank">
              <img src={work} />
            </a>
          </div>
        </div>

        <div className={style.row}>
          <div className={[style.left, style.rounded].join(' ')}>
            <div className={[style.rounded, style.firstImage].join(' ')}>
              <a href='https://www.figma.com/proto/1wkq1MuCHG11boDh849LQa/Portfolio?page-id=259%3A21&node-id=3178-12361&node-type=canvas&viewport=926%2C470%2C0.13&t=S5eSXN1ves1xgf79-1&scaling=min-zoom&content-scaling=fixed&starting-point-node-id=3178%3A12361' target='_blank'>
                <img src={work2} />
              </a>
            </div>
            <div className={style.row1}>
              <div className={style.rounded}>
                <a href='https://www.figma.com/proto/1wkq1MuCHG11boDh849LQa/Portfolio?page-id=3020%3A4823&node-id=3057-16833&node-type=frame&viewport=-1731%2C824%2C0.29&t=uRh2jc2iKmDWcQpp-1&scaling=scale-down&content-scaling=fixed&starting-point-node-id=3057%3A16833&show-proto-sidebar=1' target='_blank'>
                  <img src={work3} />
                </a>
              </div>
              <div className={style.rounded}>
                <a href='https://www.figma.com/proto/1wkq1MuCHG11boDh849LQa/Portfolio?page-id=0%3A1&node-id=3178-12362&node-type=canvas&viewport=171%2C-115%2C0.12&t=vz56EtQcqCI2FxcW-1&scaling=scale-down-width&content-scaling=fixed&starting-point-node-id=3178%3A12362' target='_blank'>
                  <img src={work4} />
                </a>
              </div>
            </div>
          </div>
          
          <div className={[style.right, style.rounded].join(' ')}>
            <a href='https://www.figma.com/proto/1wkq1MuCHG11boDh849LQa/Portfolio?page-id=2114%3A5187&node-id=2114-8125&node-type=canvas&viewport=603%2C107%2C0.02&t=lweUnW24qeOFkbS6-1&scaling=scale-down-width&content-scaling=fixed&starting-point-node-id=2114%3A8125' target='_blank'>
              <img src={toyotaOem} />
            </a>
          </div>
        </div>

        <div className={style.row}>
          <div className={[style.left, style.rounded].join(' ')}>
            <a href='https://www.figma.com/proto/1wkq1MuCHG11boDh849LQa/Portfolio?page-id=2108%3A1236&node-id=2111-13872&node-type=canvas&viewport=814%2C434%2C0.06&t=lqWPG0mmwpQ5QsBK-1&scaling=scale-down-width&content-scaling=fixed&starting-point-node-id=2111%3A13872' target='_blank'>
              <img src={work7} />
            </a>
          </div>
          
          <div className={[style.right, style.rounded].join(' ')}>
            <a href='https://www.figma.com/proto/1wkq1MuCHG11boDh849LQa/Portfolio?page-id=1408%3A457&node-id=1408-1198&node-type=canvas&viewport=538%2C609%2C0.06&t=LwSOphTivMZIq5fQ-1&scaling=scale-down-width&content-scaling=fixed&starting-point-node-id=1408%3A1198&show-proto-sidebar=1' target='_blank'>
              <img src={work8} />
            </a>
          </div>
        </div>

        <div className={style.row}>
          <div className={[style.left, style.rounded].join(' ')}>
            <a href='https://www.figma.com/proto/1wkq1MuCHG11boDh849LQa/Portfolio?node-id=3178-11631&node-type=canvas&m=dev&scaling=scale-down-width&content-scaling=fixed&page-id=240%3A2&starting-point-node-id=3178%3A11631&t=Vj3nQhgSc1TRBhYZ-1' target='_blank'>
              <img src={work9} />
            </a>
          </div>
          
          <div className={[style.right, style.rounded].join(' ')}>
            <a href='https://www.figma.com/proto/1wkq1MuCHG11boDh849LQa/Portfolio?page-id=5069%3A3&node-id=5069-4&node-type=frame&viewport=640%2C489%2C0.08&t=hRtDsisvKoAMFrun-1&scaling=scale-down-width&content-scaling=fixed&starting-point-node-id=5069%3A4' target='_blank'>
              <img src={work10} />
            </a>
          </div>
        </div>

      </div>

    </>
  )
}